<template>
  <auth-form-wrapper
    :title="$t('auth.verification.title')"
    :subtitle="$t('auth.verification.subtitle')"
    :error="error"
  >
    <!-- Success Message -->
    <b-alert
      show
      variant="success"
    >
      <div class="alert-body text-center">
        <h3 class="mb-1">{{ $t('auth.verification.success.title') }}</h3>
        <p>{{ $t('auth.verification.success.message', { email: email }) }}</p>
        <p>{{ $t('common.email-attention') }}</p>
        <template v-if="showSupportEmail">
          <p>{{ $t('common.contact-support', { email: supportEmail }) }}</p>
        </template>
      </div>
    </b-alert>

    <!-- Verification Form -->
    <b-form class="auth-form mt-2" @submit.prevent="handleSubmit">
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.verification.form.code.label') }}
        </label>
        <b-form-input
          id="verification-code"
          v-model="verificationCode"
          type="text"
          name="code"
          :placeholder="$t('auth.verification.form.code.placeholder')"
          maxlength="6"
          pattern="[0-9]{6}"
          required
          :disabled="loading"
        />
      </div>

      <!-- Submit -->
      <div class="auth-btn-container d-flex justify-content-center">
        <b-button
          type="submit"
          variant="primary"
          class="auth-btn auth-btn-primary mt-2 mb-2"
          :disabled="loading || !isValidCode"
        >
          {{ $t('auth.verification.form.action.submit') }}
        </b-button>
      </div>
    </b-form>

    <!-- Resend Code -->
    <p class="text-center mt-2">
      {{ $t('auth.verification.resend.text') }}
      <b-link 
        class="auth-link" 
        @click.prevent="handleResendCode"
      >
        {{ $t('auth.verification.resend.link') }}
      </b-link>
    </p>

    <!-- Cancel Verification -->
    <p class="text-center mt-2">
      <b-link 
        class="auth-link"
        @click.prevent="goToLogin"
      >
        {{ $t('auth.verification.cancel') }}
      </b-link>
    </p>
  </auth-form-wrapper>
</template>

<script>
import AuthFormWrapper from '@/auth/components/AuthFormWrapper.vue'

export default {
  name: 'SignupVerification',

  components: {
    AuthFormWrapper
  },

  inject: ['authApiUrl'],

  data() {
    return {
      verificationCode: '',
      loading: false,
      error: null,
      email: '',
      community: null
    }
  },

  computed: {
    isValidCode() {
      return /^[0-9]{6}$/.test(this.verificationCode)
    },

    communitySlug() {
      return this.$route.params.communityId
    },

    showSupportEmail() {
      return !this.community || !this.isIntercontinentalAlliance
    },

    isIntercontinentalAlliance() {
      return this.community?.key === '6725a490-2547-11ee-a8ab-dfc8db3ddec2'
    },

    supportEmail() {
      return this.isIntercontinentalAlliance 
        ? 'support@intercontinentalalliance.com' 
        : process.env.VUE_APP_SUPPORT_EMAIL
    }
  },

  created() {
    this.email = this.$route.query.email
    if (!this.email) {
      this.$router.push({ name: 'auth-signup' })
    }
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true
        this.error = null

        const response = await fetch(
          `${this.authApiUrl}verify-code`,
          {
            method: 'POST',
            body: JSON.stringify({
              email: this.email,
              code: this.verificationCode,
              communitySlug: this.communitySlug
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        const data = await response.json()

        if (!response.ok) {
          throw new Error(data.error === 'Code expired' ? 'auth.verification.error.code-expired' : 'auth.verification.error.invalid-code')
        }

        // Redirect to register page with token
        this.$router.push({
          name: 'auth-register',
          params: { 
            communityId: this.communitySlug
          },
          query: {
            token: data.token,
            email: this.email
          }
        })

      } catch (error) {
        console.error('Verification error:', error)
        this.error = error.message === 'auth.verification.error.code-expired' 
          ? 'auth.verification.error.code-expired'
          : 'auth.verification.error.invalid-code'
      } finally {
        this.loading = false
      }
    },

    async handleResendCode() {
      try {
        this.loading = true
        this.error = null

        const response = await fetch(
          `${this.authApiUrl}get-new-code`,
          {
            method: 'POST',
            body: JSON.stringify({
              email: this.email,
              communitySlug: this.communitySlug
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        if (!response.ok) {
          throw new Error('auth.verification.error.resend-failed')
        }

      } catch (error) {
        console.error('Resend code error:', error)
        this.error = 'auth.verification.error.resend-failed'
      } finally {
        this.loading = false
      }
    },

    goToLogin() {
      this.$router.push({
        name: 'auth-login',
        params: { 
          communityId: this.communitySlug
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-link {
  cursor: pointer;
}
</style>
